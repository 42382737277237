import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import homepageTaglineOpts from "./homepageTaglineOpts";

const S_HeroTagline = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let heroVars = {
      ...appState,
      currentPath: "/ui/hero-tagline",
      outputName: "HeroTagline",
      headerName: "Hero Section - Tagline",
      tags: null,
      description:
        "The hero section has two main areas for dynamic content, the background image/video (depending on where it's used) and foreground copy. The copy appears over the image in a 'clip' when in tablet or desktop view while the text will appear below the image in mobile view. There are several versions; please check related sections for possible options.",
      related: [
        ["Hero", "hero"],
        ["Hero: Local Video", "hero-local-video"],
        ["Hero: SVP", "hero-svp"],
        ["Hero: Vimeo", "hero-vimeo"],
      ],
      htmlSaved: true,
      currentTheme: null,
      scripts: [],
      specs: [
        [
          "Background Video (mobile, desktop)",
          [
            "*Both sizes required",
            "Small: 512 x 512 / 1x1 ratio",
            "Large: 1280 x 720 / 16x9 ratio",
            "Must be hosted on Vimeo",
            "Video clip loop Length: 10 sec. or less",
          ],
        ],
        [
          "Background Video Fallback Image",
          [
            "*2 sizes required",
            "Small: 512 x 512",
            "Large: 1280 x 720",
            "Recommended file size: 50kb to 100kb",
          ],
        ],
        ["Background Video Fallback Image Type", ["JPG"]],
        ["Background Image", ["*2 sizes required", "Small: 512 x 512", "Large: 1280 x 720"]],
        ["Background Image Type", ["JPG"]],
        ["Headline", ["Character Limit: 85"]],
        ["Link", ["Character Limit: 85"]],
        ["Tagline", ["Paragraph with character Limit: ~100"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ERrpNEEjPsJDnsNmWhvM24EBtSgLQkXPkLXW98SL_rt4fw?e=me9xR8",
        comments: [
          ["Hero Homepage Fallback Image Size", ["Small: 512 x 512", "Large: 1280 x 720"]],
          [
            "Hero Hompeage Video Sizes",
            ["Small: 512 x 512 / 1x1 ratio", "Large: 1280 x 720 / 16x9 ratio"],
          ],
          ["Background Image Size", ["Small: 512 x 512", "Large: 1280 x 720"]],
        ],
      },
    };
    setAppState(heroVars);
    setContentOptions(homepageTaglineOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "",
      width: window.innerWidth - 24,
    });
  }, []);

  //this context data must be loaded else errors are thrown, so show loading text
  if (!contentOptions || Object.keys(contentOptions).length < 8) return "...Loading Hero";
  return (
    <section
      className={`s-hero s-hero--landing${
        contentOptions.Aligned && contentOptions.Aligned.selected === "Right"
          ? " s-hero--clipRight"
          : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Left"
            ? " s-hero--clipLowerLeft"
            : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Right"
              ? " s-hero--clipLowerRight"
              : ""
      }${contentOptions.Alternate && contentOptions.Alternate.checkbox ? " s-hero--light" : ""}`}
    >
      <div
        className="s-hero__background"
        style={{
          "--fallbackSm": `url("https://media.kingston.com/kingston/landing/ironkey/ironkey_forge_Homepage-sm.jpg")`,
          "--fallbackLg": `url("/images/fpo/section-carousel/kiwi-sample-backup.jpg")`,
        }}
        // style={{
        //   backgroundImage: `url("https://media.kingston.com/kingston/hero/ktc-hero-homepage-china-lg.jpg")`,
        // }}
      >
        <div
          className="e-vimeoPlayer"
          data-vimeo-small="https://vimeo.com/335022330"
          data-vimeo-large="https://player.vimeo.com/video/451666754?muted=1&autoplay=1&autopause=0&controls=0&loop=1&background=1&app_id=122963"
        ></div>
        <div id="vimeo0" className="e-vimeoPlayer__player" data-vimeo-initialized="true">
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src={
                contentOptions.VideoL && contentOptions.VideoL.field
                  ? contentOptions.VideoL.field
                  : `https://player.vimeo.com/video/451666754?muted=1&autoplay=1&autopause=0&controls=0&loop=1&background=1&app_id=122963`
              }
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
              title="KIWY 10-sec video for KTC homepage"
              data-ready="true"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="s-hero__content">
        <div className="c-headerClip">
          <div className="u-h1 c-headerClip__title">
            {(contentOptions.Headline &&
              contentOptions.Headline.field[contentOptions.currentLang]) ||
              `Heading Text`}
          </div>
          {contentOptions.Copy && contentOptions.Copy.checkbox === true ? (
            <p className="c-headerClip__desc">
              {contentOptions.Copy.field[contentOptions.currentLang]}
            </p>
          ) : (
            ""
          )}
          <div className="c-headerClip__cta">
            {contentOptions.CTA && contentOptions.CTA.checkbox && (
              <a className="c-headerClip__cta__link" href="#heroLink">
                {contentOptions.SVG.checkbox && contentOptions.SVG.field ? (
                  <svg>
                    <use xlinkHref={`/images/icons-map.svg${contentOptions.SVG.field}`} />
                  </svg>
                ) : (
                  <svg>
                    <use xlinkHref="/images/icons-map.svg#play-basic" />
                  </svg>
                )}
                {(contentOptions.CTA && contentOptions.CTA.field[contentOptions.currentLang]) ||
                  `Hero Link`}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "hero-tagline",
  component: S_HeroTagline,
  navtxt: "Hero - Tagline",
  htmlName: "HeroTagline",
  categoryType: ["web"],
};
