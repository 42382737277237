/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import heroLocalVideoOpts from "./heroLocalVideoOpts";

const S_HeroLocalVideo = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);
  const [modalOpen, setModalOpen] = useState(false);

  const handleVideoClick = () => {
    console.log("handlevideoclick");
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    let heroVars = {
      ...appState,
      currentPath: "/ui/hero-local-video",
      outputName: "HeroLocalVideo",
      headerName: "Hero Section w/Local Video Player",
      tags: null,
      description:
        "The hero section has two main areas for dynamic content, the background image/video (depending on where it's used) and foreground copy. The copy appears over the image in a 'clip' when in tablet or desktop view while the text will appear below the image in mobile view. There are several versions; please check related sections for possible options.",
      related: [
        ["Hero", "hero"],
        ["Hero: Tagline", "hero-tagline"],
        ["Hero: SVP", "hero-svp"],
        ["Hero: Vimeo", "hero-vimeo"],
      ],
      htmlSaved: true,
      currentTheme: null,
      scripts: [
        "../../js/rexusManager.component.js",
        "../../js/cuid.component.js",
        "../../js/modal.component.js",
        "youtube",
        "../../js/externalVideo.component.js",
      ],
      specs: [
        [
          "Background Video",
          ["Small: 512 x 512", "Large: 1280 x 720", "Video clip loop Length: 10 sec. or less"],
        ],
        [
          "Background Video Fallback Image",
          [
            "*2 sizes required",
            "Small: 512 x 512",
            "Large: 2048 x 768",
            "Recommended file size: 50kb to 100kb",
          ],
        ],
        ["Background Video Fallback Image Type", ["JPG"]],
        ["Headline", ["Character Limit: ~35"]],
        ["Link", ["Character Limit: 85"]],
        ["Tagline", ["Only available on home page"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ERrpNEEjPsJDnsNmWhvM24EBtSgLQkXPkLXW98SL_rt4fw?e=me9xR8",
        comments: [
          ["Hero Local Video Fallback Image", ["2048 x 768"]],
          ["Background Video", ["Small: 512 x 512", "Large: 1280 x 720"]],
        ],
      },
    };
    setAppState(heroVars);
    setContentOptions(heroLocalVideoOpts);
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "525",
      width: window.innerWidth - 24,
    });
  }, []);

  //this context data must be loaded else errors are thrown, so show loading text
  if (!contentOptions) return "...Loading Hero";
  return (
    <div>
      <section
        className={`s-hero${
          contentOptions.Aligned && contentOptions.Aligned.selected === "Right"
            ? " s-hero--clipRight"
            : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Left"
              ? " s-hero--clipLowerLeft"
              : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Right"
                ? " s-hero--clipLowerRight"
                : ""
        }${contentOptions.Alternate && contentOptions.Alternate.checkbox ? " s-hero--light" : ""}`}
        onClick={() => handleVideoClick()}
        role="button"
      >
        <a
          className="s-hero__link"
          href="#anchorLink"
          title="Anchor Title"
          data-modal="video"
          aria-labelledby="describeModal"
        >
          {" "}
        </a>
        <div
          className="s-hero__background"
          style={{
            "--fallbackSm": `url("https://media.kingston.com/kingston/landing/ironkey/ironkey_forge_Homepage-sm.jpg")`,
            "--fallbackLg": `url("/images/fpo/section-carousel/kiwi-sample-backup.jpg")`,
          }}
        >
          <video
            className="s-hero__background__small"
            src="/images/fpo/section-hero/hero-video-sm.mp4"
            title="Video Title"
            muted="muted"
            autoPlay="autoplay"
            playsInline="playsinline"
            loop="loop"
          ></video>
          <video
            className="s-hero__background__medium s-hero__background__large"
            src="/images/fpo/section-hero/hero-video-lg1.mp4"
            title="Video Title"
            muted="muted"
            autoPlay="autoplay"
            playsInline="playsinline"
            loop="loop"
          ></video>
        </div>
        <div className="s-hero__content">
          <div className="c-headerClip">
            {contentOptions.HeadlineMode && contentOptions.HeadlineMode.selected === "DIV" ? (
              <div className="u-h1 c-headerClip__title">
                {(contentOptions.Headline &&
                  contentOptions.Headline.field[contentOptions.currentLang]) ||
                  `Heading Text`}
              </div>
            ) : (
              <h1 className="c-headerClip__title">
                {(contentOptions.Headline &&
                  contentOptions.Headline.field[contentOptions.currentLang]) ||
                  `Heading Text`}
              </h1>
            )}
            <div className="c-headerClip__cta">
              {contentOptions.CTA && contentOptions.CTA.checkbox && (
                <a className="c-headerClip__cta__link" href="#heroLink">
                  {contentOptions.SVG.checkbox && contentOptions.SVG.field ? (
                    <svg>
                      <use xlinkHref={`/images/icons-map.svg${contentOptions.SVG.field}`} />
                    </svg>
                  ) : (
                    <svg>
                      <use xlinkHref="/images/icons-map.svg#play-basic" />
                    </svg>
                  )}
                  {contentOptions.CTA.field[contentOptions.currentLang] || `Hero Link`}
                </a>
              )}
            </div>
          </div>
        </div>
      </section>
      <div
        className={`c-modal c-modal--darkmode ${modalOpen ? "c-modal--open" : ""}`}
        role="dialog"
        id="video"
        aria-modal="true"
        aria-hidden={modalOpen ? false : true}
      >
        <div className="c-modal__overlay"></div>
        <button
          className="c-modal__close"
          aria-label="close"
          data-close="true"
          onClick={() => handleVideoClick()}
        >
          <svg viewBox="0 0 14 14">
            <path d="M.46 12.023L11.772.709l1.768 1.768L2.227 13.791z"></path>
            <path d="M2.227.71l11.314 11.313-1.768 1.768L.459 2.477z"></path>
          </svg>
        </button>
        <div className="c-modal__content">
          <div
            className="c-externalVideo"
            data-type="Youtube"
            data-id="1MieluM0c6c"
            data-starttime="1"
            data-modal-link="video"
          >
            <iframe
              src="https://www.youtube.com/embed/1MieluM0c6c?start=1&amp;enablejsapi=1"
              id="lnk-ve9x7s"
              data-modal-link="video"
              title="local video"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ or C_. It's used by IframeWrapper to load the html version of the section. It can't be gotten from the state if the react version is never loaded.
export default {
  path: "hero-local-video",
  component: S_HeroLocalVideo,
  navtxt: "Hero - Local Video",
  htmlName: "HeroLocalVideo",
  categoryType: ["web"],
};
